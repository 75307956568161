<template>
  <v-card>
    <v-card-title class="msaBlue white--text"> Attached Users </v-card-title>
    <v-data-table
      :headers="headers"
      :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
      :items="items"
      :server-items-length="itemsCount"
      :options.sync="options"
      @update:options="getAttachedEmployees"
      must-sort
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              text
              small
              v-on="on"
              @click="detach(item.id)"
              :disabled="isProjectArchived"
            >
              <v-icon color="red"> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Detach</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-spacer></v-spacer>
      <AddProjectEmployee
        :id="id"
        :key="reload"
        :isProjectArchived="isProjectArchived"
        @confirmed="getAttachedEmployees()"
        @close="reload += 1"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import AddProjectEmployee from '@/components/WAProjects/AddProjectEmployee.vue';
export default {
  name: 'ProjectEmployees',
  components: {
    AddProjectEmployee,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isProjectArchived: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      reload: 2,
      items: [],
      itemsCount: 0,
      headers: [
        {
          text: 'Full name (Username)',
          value: 'name',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'headerTextGrey--text lightGrey',
          width: '40px',
        },
      ],
      options: {
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
        sortBy: ['name'],
        sortDesc: [false],
      },
    };
  },
  methods: {
    getAttachedEmployees() {
      const params = {
        id: this.id,
        options: this.options,
        loaderText: 'Loading...',
      };
      const url = 'get-attached-employees-for-project?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.count;
        })
        .catch((error) => error);
    },
    detach(userId) {
      const params = {
        employeeId: userId,
        projectId: this.id,
        loaderText: 'Loading...',
      };
      const url = 'remove-project-from-user?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.getAttachedEmployees();
        })
        .catch((error) => error);
    },
  },
  mounted() {
    this.getAttachedEmployees();
  },
};
</script>

<style></style>
