<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="msaBlue white--text">
            General Information
          </v-card-title>
          <v-card-text class="mt-4">
            <v-row align="center">
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="edited.name"
                  dense
                  counter
                  maxlength="1000"
                  outlined
                  label="Name"
                  :rules="required"
                  data-testid="projectName"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="edited.description"
                  counter
                  maxlength="2000"
                  outlined
                  hide-details
                  label="Description"
                  data-testid="projectDescription"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="edited.address"
                  dense
                  counter
                  maxlength="1000"
                  outlined
                  label="Address"
                  data-testid="projectAddress"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="edited.startDate"
                      readonly
                      dense
                      outlined
                      clearable
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      prepend-icon="mdi-calendar"
                      label="Start Date"
                      @click:clear="edited.startDate = ''"
                      data-testid="projectStartDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="edited.startDate"
                    no-title
                    scrollable
                    @input="startDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="edited.endDate"
                      readonly
                      dense
                      outlined
                      clearable
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      prepend-icon="mdi-calendar"
                      label="End Date"
                      @click:clear="edited.endDate = ''"
                      data-testid="projectEndDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="edited.endDate"
                    no-title
                    scrollable
                    :min="edited.startDate == '' ? undefined : edited.startDate"
                    @input="endDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="center" v-if="!isNew">
              <v-col class="shrink"> Status </v-col>
              <v-col align="left">
                <v-btn-toggle v-model="edited.status" mandatory>
                  <v-btn
                    width="100px"
                    :value="1"
                    :class="
                      (edited.status ? 'green white--text' : '') +
                      ' text-capitalize'
                    "
                    data-testid="projectActive"
                  >
                    Active
                  </v-btn>
                  <v-btn
                    width="100px"
                    :value="0"
                    :class="
                      (edited.status ? '' : 'orange white--text') +
                      ' text-capitalize'
                    "
                    data-testid="projectArchived"
                  >
                    Archived
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="right">
                <v-btn
                  class="msaBlue white--text"
                  :disabled="disableSave"
                  @click="saveDetails()"
                  data-testid="projectSave"
                >
                  save
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="!isNew && !loading" cols="12" md="6">
        <v-row>
          <v-col>
            <ProjectEmployees
              :id="project.id"
              :isProjectArchived="isProjectArchived"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="msaBlue white--text">
                Attached Items
              </v-card-title>
              <v-data-table
                :headers="itemHeaders"
                :items="attachedItems"
                :hide-default-footer="true"
                :disable-sort="true"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <a @click="item.actions">See All</a>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProjectEmployees from '@/components/WAProjects/ProjectEmployees.vue';
export default {
  name: 'ProjectDetailsPage',
  components: {
    ProjectEmployees,
  },
  data() {
    return {
      project: {
        id: -1,
        name: '',
        address: '',
        startDate: '',
        endDate: '',
        status: 1,
      },
      attachedItems: [],
      itemHeaders: [
        {
          text: 'Item Type',
          value: 'types',
          class: 'headerTextGrey--text lightGrey',
        },
        {
          text: 'Item Count',
          value: 'counts',
          class: 'headerTextGrey--text lightGrey',
        },
        {
          text: 'Actions',
          value: 'actions',
          class: 'headerTextGrey--text lightGrey',
          width: '120px',
        },
      ],
      edited: { name: '' },
      required: [(v) => !!v || 'This field is required'],
      startDateMenu: false,
      endDateMenu: false,
      loading: true,
    };
  },
  computed: {
    disableSave() {
      return (
        JSON.stringify(this.edited) == JSON.stringify(this.project) ||
        this.edited.name.trim().length == 0
      );
    },
    isNew() {
      return this.project.id == -1;
    },
    isProjectArchived() {
      if (this.project.status) {
        return false;
      }

      return true;
    },
  },
  methods: {
    goToForms() {
      this.$router.push({
        name: 'CompanyForms',
        query: {
          projectIds: [this.project.id].toString(),
          createdDateIntervalId: this.$constants.FILTER_INTERVALS.ALL_TIME,
        },
      });
    },
    goToDocuments() {
      if (this.project.id) {
        this.$router.push({
          name: 'CompanyAssignedDocuments',
          query: { projectIds: this.project.id.toString() }, // Value needs to be toString to be used by $helpers.deserializeFilters due to use of .split
        });
      }
    },
    getDetails() {
      if (this.$route.params.id != -1) {
        const params = {
          loaderText: 'Loading...',
          id: parseInt(this.$route.params.id),
        };
        const url = 'get-project-details?format=json';
        return this.$axios
          .post(url, params)
          .then((response) => {
            this.$store.commit('updateSelectedProject', response.data);

            this.project = {
              ...this.$store.getters.selectedProject,
            };

            if (this.project.startDate != '') {
              this.project.startDate = this.$options.filters.filterAsLocalDate(
                this.project.startDate,
              );
            }

            if (this.project.endDate != '') {
              this.project.endDate = this.$options.filters.filterAsLocalDate(
                this.project.endDate,
              );
            }

            this.edited = { ...this.project };

            this.attachedItems = [
              {
                types: 'Forms',
                counts: this.project.formsCount,
                actions: () => this.goToForms(),
              },
              {
                types: 'Documents',
                counts: this.project.documentsCount,
                actions: () => this.goToDocuments(),
              },
            ];

            this.loading = false;
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              this.$root.showMessage(
                '',
                error.response.data,
                () => this.$router.replace({ name: 'CompanyProjects' }),
                null,
                'ok',
                null,
                true,
              );
            } else {
              return error;
            }
          });
      }

      this.project = { ...this.$store.getters.selectedProject };
      if (this.project.startDate != '') {
        this.project.startDate = this.$options.filters.filterAsLocalDate(
          this.project.startDate,
        );
      }
      if (this.project.endDate != '') {
        this.project.endDate = this.$options.filters.filterAsLocalDate(
          this.project.endDate,
        );
      }
      this.edited = { ...this.project };
    },
    saveDetails() {
      const edited = { ...this.edited };

      if (edited.startDate && edited.startDate != '') {
        edited.startDate = this.$helpers.formatDateTimeToUTC(edited.startDate);
      } else {
        edited.startDate = '';
      }

      if (edited.endDate && edited.endDate != '') {
        edited.endDate = this.$helpers.formatDateTimeToUTC(edited.endDate);
      } else {
        edited.endDate = '';
      }

      if (!this.isNew) {
        if (edited.status != this.project.status) {
          edited.statusChanged = true;
        } else {
          edited.statusChanged = false;
        }
      }

      const params = {
        loaderText: 'Loading...',
        project: edited,
        snackText: 'Project saved successfully.',
      };
      const url = this.isNew
        ? 'add-project?format=json'
        : 'update-project?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          if (this.isNew) {
            this.$store.commit('updateSelectedProject', {
              ...edited,
              id: response.data,
            });
            this.$router.replace({
              name: this.$route.name,
              params: { id: response.data },
            });
          } else {
            this.project = { ...this.edited };
            this.$store.commit('updateSelectedProject', this.edited);
          }
        })
        .catch(() => this.getDetails());
    },
  },
  mounted() {
    this.getDetails();
  },
  beforeMount() {
    // Needed when creating project
    this.$axios.post('public/ping?format=json', {});
  },
};
</script>
