<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-blur
        class="msaBlue white--text"
        v-bind="attrs"
        v-on="on"
        @click="getEmployees()"
        :disabled="isProjectArchived"
      >
        Attach Users
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text"> Attach Users </v-card-title>
      <v-card-text class="mt-4">
        <AutocompleteWithList
          placeholder="Select user(s)"
          :items="items"
          multiple
          @change="onSelect($event)"
          :key="reload"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="red--text" @click="close()">cancel</v-btn>
        <v-btn
          class="msaBlue white--text"
          @click="confirm()"
          :disabled="selected.length == 0"
        >
          confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AutocompleteWithList from '@/components/AutocompleteWithList.vue';
export default {
  name: 'AddProjectEmployee',
  components: {
    AutocompleteWithList,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isProjectArchived: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      dialog: false,
      items: [],
      reload: false,
    };
  },
  methods: {
    getEmployees() {
      const params = {
        id: this.id,
        loaderText: 'loading...',
      };
      const url = 'get-available-employees-for-project?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => error);
    },
    confirm() {
      const params = {
        userIds: this.selected,
        projectIds: [this.id],
        loaderText: 'loading...',
      };
      this.$axios
        .post('add-projects-to-employees?format=json', params)
        .then(() => {
          this.$emit('confirmed');
          this.close();
        });
    },
    onSelect(event) {
      this.selected = JSON.parse(JSON.stringify(event));
    },
    close() {
      this.$emit('close');
      this.dialog = false;
    },
  },
};
</script>

<style></style>
